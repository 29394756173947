@import './variable.scss';

// @font-face {
// 	font-family: 'Mitr-Light';
// 	src: url("../assets/fonts/Mitr/Mitr-Light.ttf");
//   }

  @font-face {
	font-family: 'Anuphan-Regular';
	// src: url("../assets/fonts/OTF/Anuphan_Light.otf");
	src: url("../assets/fonts/OTF/Anuphan-Regular.ttf");
  }


html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: $bg;
	font-family: 'Anuphan-Regular', 'sans-serif' !important;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

#app {
	height: 100%;
}

.trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 24px;
	cursor: pointer;
	transition: color 0.3s;
  }
  
  .trigger:hover {
	color: #1890ff;
  }
  
  .logo {
	height: 32px;
	background: rgba(255, 255, 255, 0.3);
	margin: 16px;
  }
  
  .site-layout .site-layout-background {
	background: #fff;
  }

  .ant-picker, .ant-select-selector, .ant-modal-content {
	border-radius: 10px !important;
}

.ant-select-dropdown {
	border-radius: 10px !important;
}

.ant-pagination-item-active {
	border-radius: 10px !important;
}

.ant-btn {
	border-radius: 32px !important;
}

.ant-modal-header {
	border-radius: 10px 10px 0 0 !important;
}

textarea.ant-input {
	border-radius: 10px !important;
}